import React from "react";
import styles from "./AsideMenuComponent.module.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import YouTubeIcon from "@mui/icons-material/YouTube";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ListIcon from "@mui/icons-material/List";
import HouseIcon from "@mui/icons-material/House";
import { AuthContext } from "../../auth/authContext";
import { checkPermisosMenuPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { ImprimirPantallaComponent } from "../ImprimirPantallaComponent/ImprimirPantallaComponent";
import { SearchInAllWebComponent } from "../SearchInAllWebComponent/SearchInAllWebComponent";
import { toast } from "react-toastify";
import { types } from "../../types/types";

import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import SettingsIcon from "@mui/icons-material/Settings";

import ManageSearchIcon from "@mui/icons-material/ManageSearch";

import PaymentsIcon from "@mui/icons-material/Payments";
import { ConfigurationContext } from "./../../configuration/configurationContext";

export const AsideMenuComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);

  const { configuration, dispatchConfiguration } =
    React.useContext(ConfigurationContext);

  const [menuContratos, setMenuContratos] = React.useState(false);
  const [menuAgregarPagosAContratos, setMenuAgregarPagosAContratos] =
    React.useState(false);
  const [menuOrdenesDeServicio, setMenuOrdenesDeServicio] =
    React.useState(false);
  const [menuBuscadorDePagos, setMenuBuscadorDePagos] = React.useState(false);
  const [menuReportes, setMenuReportes] = React.useState(false);
  const [menuPanteones, setMenuPanteones] = React.useState(false);
  const [menuPanelDeControl, setMenuPanelDeControl] = React.useState(false);

  const [
    menuCatalogosServicioOrdenesServicio,
    setMenuCatalogosServicioOrdenesServicio,
  ] = React.useState(false);
  const [menuUsuarios, setMenuUsuarios] = React.useState(false);
  const [menuEmpleados, setMenuEmpleados] = React.useState(false);

  const [abierto, setAbierto] = React.useState(configuration.menuAbierto);

  const [menuAniosMantenimiento, setMenuAniosMantenimiento] =
    React.useState(false);

  React.useEffect(() => {
    let listo = false;

    checkPermisosMenuPorUsuarioPorGuidPantalla(user).then((resultado) => {
      if (resultado.status == 401) {
        toast.error("Cerrando Sesion");

        dispatch({
          type: types.logout,
        });
        window.location.href = "/";
      }
      if (resultado.status == 200) {
        for (const permiso of resultado.data) {
          if (
            permiso.guidVista == "6ac593c2-4810-4081-bb29-e80830750a85" &&
            permiso.allowed == 1
          ) {
            setMenuContratos(true);
          }
          if (
            permiso.guidVista == "db093ac2-e36c-402c-a7c3-b39a8c2fbc63" &&
            permiso.allowed == 1
          ) {
            setMenuAgregarPagosAContratos(true);
          }
          if (
            permiso.guidVista == "d5376ba2-cefd-4edd-8402-362d5bd4c75d" &&
            permiso.allowed == 1
          ) {
            setMenuOrdenesDeServicio(true);
          }
          if (
            permiso.guidVista == "ef433e95-e3b0-4caa-a533-3b599add112e" &&
            permiso.allowed == 1
          ) {
            setMenuReportes(true);
          }
          if (
            permiso.guidVista == "6b203d5f-1db7-4edf-a929-d8e21c15e9aa" &&
            permiso.allowed == 1
          ) {
            setMenuPanteones(true);
          }
          if (
            permiso.guidVista == "5e421c58-c48f-41ff-b746-c27c5d627c1a" &&
            permiso.allowed == 1
          ) {
            setMenuCatalogosServicioOrdenesServicio(true);
          }
          if (
            permiso.guidVista == "678e34bf-f746-45e7-acb8-61d5893faf85" &&
            permiso.allowed == 1
          ) {
            setMenuUsuarios(true);
          }
          if (
            permiso.guidVista == "03b35603-5099-4f9d-92d7-08be6119f007" &&
            permiso.allowed == 1
          ) {
            setMenuEmpleados(true);
          }
          if (
            permiso.guidVista == "8686295e-f527-446d-b48a-119c51d6dac8" &&
            permiso.allowed == 1
          ) {
            setMenuBuscadorDePagos(true);
          }

          if (
            permiso.guidVista == "f1424bf4-c8fa-40ec-b833-8e16fc0100e3" &&
            permiso.allowed == 1
          ) {
            setMenuAniosMantenimiento(true);
          }
          if (
            permiso.guidVista == "661ed266-3ea9-4b72-9987-9e0bb02d009b" &&
            permiso.allowed == 1
          ) {
            setMenuPanelDeControl(true);
          }
        }
      }
    });

    return () => {
      listo = true;
    };
  }, []);

  return (
    <div
      className={
        process.env.REACT_APP_CONNECTION_MODE == "Development" ||
        process.env.REACT_APP_CONNECTION_MODE == "Staging"
          ? `${styles.menuContainerDev} d-print-none`
          : `${styles.menuContainer} d-print-none`
      }
    >
      <ImprimirPantallaComponent />
      <div className={styles.menuScroll}>
        <NavLink
          to="/"
          className={(navData) =>
            navData.isActive ? "classSelected" : styles.buttonMenu
          }
          style={
            configuration.menuAbierto === true
              ? { textAlign: "left" }
              : { textAlign: "center" }
          }
        >
          <span>
            <HomeIcon fontSize="small" />
            {configuration.menuAbierto === true ? <span>Inicio</span> : null}
          </span>
        </NavLink>
        {menuContratos == true ? (
          <NavLink
            to="/contratos"
            className={(navData) =>
              navData.isActive ? "classSelected" : styles.buttonMenu
            }
            style={
              configuration.menuAbierto === true
                ? { textAlign: "left" }
                : { textAlign: "center" }
            }
          >
            <span>
              <DocumentScannerIcon fontSize="small" />
              {configuration.menuAbierto === true ? (
                <span>Contratos</span>
              ) : null}
            </span>
          </NavLink>
        ) : null}

        {/*menuAgregarPagosAContratos == true ? (
          <NavLink
            to="/pagoscontratos"
            className={(navData) =>
              navData.isActive ? "classSelected" : styles.buttonMenu
            }
            style={
              configuration.menuAbierto === true
                ? { textAlign: "left" }
                : { textAlign: "center" }
            }
          >
            <span>
              <AttachMoneyIcon fontSize="small" />
              {configuration.menuAbierto === true ? (
                <span>Agregar Pagos a Contratos</span>
              ) : null}
            </span>
          </NavLink>
              ) : null */}

        {menuBuscadorDePagos == true ? (
          <NavLink
            to="/buscadordepagos"
            className={(navData) =>
              navData.isActive ? "classSelected" : styles.buttonMenu
            }
            style={
              configuration.menuAbierto === true
                ? { textAlign: "left" }
                : { textAlign: "center" }
            }
          >
            <span>
              <AttachMoneyIcon fontSize="small" />
              {configuration.menuAbierto === true ? (
                <span>Buscar Recibos</span>
              ) : null}
            </span>
          </NavLink>
        ) : null}
        {menuOrdenesDeServicio == true ? (
          <NavLink
            to="/ordenes"
            className={(navData) =>
              navData.isActive ? "classSelected" : styles.buttonMenu
            }
            style={
              configuration.menuAbierto === true
                ? { textAlign: "left" }
                : { textAlign: "center" }
            }
          >
            <span>
              <RoomServiceIcon fontSize="small" />
              {configuration.menuAbierto === true ? (
                <span>Órdenes de Servicio</span>
              ) : null}
            </span>
          </NavLink>
        ) : null}
        {menuReportes == true ? (
          <NavLink
            to="/reportes"
            className={(navData) =>
              navData.isActive ? "classSelected" : styles.buttonMenu
            }
            style={
              configuration.menuAbierto === true
                ? { textAlign: "left" }
                : { textAlign: "center" }
            }
          >
            <span>
              <AssessmentIcon fontSize="small" />
              {configuration.menuAbierto === true ? (
                <span>Reportes</span>
              ) : null}
            </span>
          </NavLink>
        ) : null}

        {menuPanteones == true ? (
          <NavLink
            to="/catalogoespacios"
            className={(navData) =>
              navData.isActive ? "classSelected" : styles.buttonMenu
            }
            style={
              configuration.menuAbierto === true
                ? { textAlign: "left" }
                : { textAlign: "center" }
            }
          >
            <span>
              <HouseIcon fontSize="small" />
              {configuration.menuAbierto === true ? (
                <span>Serentia</span>
              ) : null}
            </span>
          </NavLink>
        ) : null}

        {menuPanelDeControl == true ? (
          <NavLink
            to="/paneldecontrol"
            className={(navData) =>
              navData.isActive ? "classSelected" : styles.buttonMenu
            }
            style={
              configuration.menuAbierto === true
                ? { textAlign: "left" }
                : { textAlign: "center" }
            }
          >
            <span>
              <SettingsIcon fontSize="small" />
              {configuration.menuAbierto === true ? (
                <span>Panel de Control</span>
              ) : null}
            </span>
          </NavLink>
        ) : (
          false
        )}

        <NavLink
          to="/buscadorenespacios"
          className={(navData) =>
            navData.isActive ? "classSelected" : styles.buttonMenu
          }
          style={
            configuration.menuAbierto === true
              ? { textAlign: "left" }
              : { textAlign: "center" }
          }
        >
          <span>
            <ManageSearchIcon fontSize="small" />
            {configuration.menuAbierto === true ? (
              <span>Buscador en espacios</span>
            ) : null}
          </span>
        </NavLink>

        <NavLink
          to="/estadodecuentacontratos"
          className={(navData) =>
            navData.isActive ? "classSelected" : styles.buttonMenu
          }
          style={
            configuration.menuAbierto === true
              ? { textAlign: "left" }
              : { textAlign: "center" }
          }
        >
          <span>
            <AccountBalanceWalletIcon fontSize="small" />
            {configuration.menuAbierto === true ? (
              <span>E. Cuenta Contratos</span>
            ) : null}
          </span>
        </NavLink>
        {/*  <NavLink
          to="/estadodecuentacontratosdos"
          className={(navData) =>
            navData.isActive ? "classSelected" : styles.buttonMenu
          }
          style={
            configuration.menuAbierto === true
              ? { textAlign: "left" }
              : { textAlign: "center" }
          }
        >
          <span>
            <AccountBalanceWalletIcon fontSize="small" />
            {configuration.menuAbierto === true ? (
              <span>E. Cuenta Contratos 2.0</span>
            ) : null}
          </span>
        </NavLink> */}

        <NavLink
          to="/estadodecuentaordenesservicio"
          className={(navData) =>
            navData.isActive ? "classSelected" : styles.buttonMenu
          }
          style={
            configuration.menuAbierto === true
              ? { textAlign: "left" }
              : { textAlign: "center" }
          }
        >
          <span>
            <AccountBalanceWalletIcon fontSize="small" />

            {configuration.menuAbierto === true ? (
              <span> E. Cuenta órdenes Servicio</span>
            ) : null}
          </span>
        </NavLink>
        <NavLink
          to={""}
          target="_self"
          onClick={() => {
            window.open(
              "https://www.youtube.com/playlist?list=PLEBfChArjIfZOYbhFaDRuiWcjBVyAPGoE",
              "_self"
            );
          }}
          style={
            configuration.menuAbierto === true
              ? { textAlign: "left" }
              : { textAlign: "center" }
          }
          className={(navData) => styles.buttonMenu}
        >
          <span>
            <YouTubeIcon fontSize="small" />
            {configuration.menuAbierto === true ? <span>Manuales</span> : null}
          </span>
        </NavLink>

        <NavLink
          to="/ingresarpagos"
          className={(navData) =>
            navData.isActive ? "classSelected" : styles.buttonMenu
          }
          style={
            configuration.menuAbierto === true
              ? { textAlign: "left" }
              : { textAlign: "center" }
          }
        >
          <span>
            <PaymentsIcon fontSize="small" />
            {configuration.menuAbierto === true ? (
              <span>Ingresar Pagos</span>
            ) : null}
          </span>
        </NavLink>
      </div>
    </div>
  );
};
