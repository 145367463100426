import jwtDecode from "jwt-decode";
import React from "react";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { AgregarPagoComponent } from "../AgregarPagoComponent/AgregarPagoComponent";
import { EditarPagoComponent } from "../EditarPagoComponent/EditarPagoComponent";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { AuthContext } from "../../auth/authContext";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { EditarPagoCambiarDeContratoComponent } from "../EditarPagoCambiarDeContratoComponent/EditarPagoCambiarDeContratoComponent";
import { EditarPagoMantenimientoComponent } from "../EditarPagoMantenimientoComponent/EditarPagoMantenimientoComponent";
import CambiarFormatoFechaHelper from "../../Helpers/CambiarFormatoFechaHelper";
import { toast } from "react-toastify";
import { EliminarPagoContratoComponent } from "../EliminarPagoContratoComponent/EliminarPagoContratoComponent";
import DataTable from "react-data-table-component";

const guidVista = "db093ac2-e36c-402c-a7c3-b39a8c2fbc63";

export const ListadoDePagosDeContratoComponent = (props) => {
  const columns = [
    {
      name: "Tipo",
      selector: (row) => "Pago",
      sortable: true,
    },
    {
      name: "Serie",
      selector: (row) => row.serie,
      sortable: true,
    },
    {
      name: "Folio",
      selector: (row) => row.folio,
      sortable: true,
    },
    {
      name: "Fecha",
      selector: (row) => row.fechaPago,
      sortable: true,
    },
    {
      name: "Monto",
      selector: (row) =>
        "$" +
        parseFloat(row.monto)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
      sortable: true,
    },
    {
      name: "Editar",
      selector: (row) => {
        return (
          <>
            {permisoParaEditar == true ? (
              <>
                <div
                  className="btn btn-sm btn-outline-success"
                  onClick={() => {
                    setCrudAction({
                      action: row.serie == "M" ? "editMantenimiento" : "edit",
                      data: row,
                    });
                  }}
                >
                  Editar
                </div>
              </>
            ) : null}
          </>
        );
      },
    },
    {
      name: "Mover Pago",
      selector: (row) => {
        return (
          <>
            {permisoParaEditar == true ? (
              <>
                <div
                  className="btn btn-sm btn-success"
                  onClick={() => {
                    setCrudAction({
                      action: "changeContrato",
                      data: row,
                    });
                  }}
                >
                  <CurrencyExchangeIcon />
                </div>
              </>
            ) : null}
          </>
        );
      },
    },
    {
      name: "Eliminar",
      selector: (row) => {
        return (
          <>
            {permisoParaEliminar == true ? (
              <>
                <div
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    if (row.serie == "M") {
                      toast.info(
                        "No esta permitido eliminar pagos de mantenimiento"
                      );
                      return;
                    } else {
                      setCrudAction({
                        action: "delete",
                        data: row,
                      });
                    }
                  }}
                >
                  Eliminar
                </div>
              </>
            ) : null}
          </>
        );
      },
    },
  ];

  const columnsRestructura = [
    {
      name: "Tipo",
      selector: (row) => "Restructura",
      sortable: true,
    },
    {
      name: "Serie",
      selector: (row) => row.serie,
      sortable: true,
    },
    {
      name: "Folio",
      selector: (row) => row.folio,
      sortable: true,
    },
    {
      name: "Fecha",
      selector: (row) => row.fechaPago,
      sortable: true,
    },
    {
      name: "Monto",
      selector: (row) =>
        "$" +
        parseFloat(row.monto)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
      sortable: true,
    },
  ];

  const { user, dispatch } = React.useContext(AuthContext);
  const [finalDataPagos, setFinalDataPagos] = React.useState(
    props.finalData.pagos
  );

  const [finalDataPagosRestructura, setFinalDataPagosRestructura] =
    React.useState(props.finalData.restructura);

  console.log(finalDataPagosRestructura);

  console.log(props.finalData.restructura);

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  const [totalPagosRealizados, setTotalPagosRealizados] = React.useState(0);
  const [totalPagosMantenimiento, setTotalPagosMantenimiento] =
    React.useState(0);
  const [totalDineroReacaudado, setTotalDineroReacaudado] = React.useState(0.0);

  React.useEffect(() => {
    var listo = false;

    let totalPagos = 0;
    let totalDinero = 0.0;
    let totalMantenimiento = 0.0;

    for (const registroPago of finalDataPagos) {
      totalPagos = totalPagos + 1;
      if (registroPago.serie == "M") {
        totalMantenimiento = parseFloat(registroPago.monto);
      } else {
        totalDinero = totalDinero + parseFloat(registroPago.monto);
      }
    }

    /* for (const pagosRestructurados of props.finalData.restructura
      ?.pagosContratoAnterior) {
      totalPagos = totalPagos + 1;
      totalDinero = totalDinero + parseFloat(pagosRestructurados.monto);
    }
*/
    setTotalPagosRealizados(totalPagos);
    setTotalPagosMantenimiento(totalMantenimiento);
    setTotalDineroReacaudado(totalDinero);

    return () => {
      listo = true;
    };
  }, []);

  React.useEffect(() => {
    var listo = false;

    let totalPagos = 0;
    let totalDinero = 0.0;
    let totalMantenimiento = 0.0;

    for (const registroPago of finalDataPagos) {
      totalPagos = totalPagos + 1;
      if (registroPago.serie == "M") {
        totalMantenimiento = parseFloat(registroPago.monto);
      } else {
        totalDinero = totalDinero + parseFloat(registroPago.monto);
      }
    }

    for (const contratosPagos of finalDataPagosRestructura) {
      for (const pago of contratosPagos.pagosContrato) {
        totalPagos = totalPagos + 1;
        totalDinero = totalDinero + parseFloat(pago.monto);
      }
    }

    setTotalPagosRealizados(totalPagos);
    setTotalPagosMantenimiento(totalMantenimiento);
    setTotalDineroReacaudado(totalDinero);

    return () => {
      listo = true;
    };
  }, [finalDataPagosRestructura, finalDataPagos, props.contadorActualizar]);

  const [permisoParaAgregar, setPermisoParaAgregar] = React.useState(false);
  const [permisoParaVisualizar, setPermisoParaVisualizar] =
    React.useState(false);
  const [permisoParaEditar, setPermisoParaEditar] = React.useState(false);
  const [permisoParaEliminar, setPermisoParaEliminar] = React.useState(false);
  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "fc9c551d-2e0d-4385-9db7-6e65909807a7" &&
              permiso.allowed == 1
            ) {
              setPermisoParaAgregar(true);
            }
            if (
              permiso.guidFuncion == "30124343-bfdd-46c1-9e60-10a8200890c1" &&
              permiso.allowed == 1
            ) {
              setPermisoParaEditar(true);
            }
            if (
              permiso.guidFuncion == "d3188181-5e66-48e7-a741-2fd1c9e3fb5d" &&
              permiso.allowed == 1
            ) {
              setPermisoParaEliminar(true);
            }
          }
        }
      }
    );

    return () => {
      ready = true;
    };
  }, []);

  return (
    <>
      <div className="container-fluid pt-3">
        <div className="row">
          <div className="col-md-9">
            <div className="container-fluid">
              <div className="row row-cols-md-1">
                <div className="col-12">
                  {finalDataPagos.length > 0 ? (
                    <DataTable
                      columns={columns}
                      data={finalDataPagos}
                      fixedHeader={true}
                      fixedHeaderScrollHeight={"50vh"}
                    />
                  ) : null}

                  {finalDataPagosRestructura[0]?.pagosContrato.length > 0 ? (
                    <DataTable
                      columns={columnsRestructura}
                      data={finalDataPagosRestructura[0]?.pagosContrato}
                      fixedHeader={true}
                      fixedHeaderScrollHeight={"50vh"}
                    />
                  ) : null}
                </div>

                {finalDataPagos.map((pagoRealizado, index) => (
                  <div key={index} className="col d-none">
                    <div className="card mb-2">
                      <div className="card-header d-flex justify-content-between">
                        <strong>
                          <ConfirmationNumberIcon
                            className=""
                            fontSize="small"
                          />{" "}
                          &nbsp; {pagoRealizado.serie} - {pagoRealizado.folio}
                        </strong>
                        <strong>{pagoRealizado.noContrato}</strong>
                        {permisoParaEditar == true ? (
                          <>
                            <div
                              className="btn btn-sm btn-outline-success"
                              onClick={() => {
                                setCrudAction({
                                  action:
                                    pagoRealizado.serie == "M"
                                      ? "editMantenimiento"
                                      : "edit",
                                  data: pagoRealizado,
                                });
                              }}
                            >
                              Editar
                            </div>
                            <div
                              className="btn btn-sm btn-success"
                              onClick={() => {
                                setCrudAction({
                                  action: "changeContrato",
                                  data: pagoRealizado,
                                });
                              }}
                            >
                              <CurrencyExchangeIcon />
                            </div>
                          </>
                        ) : null}
                        {permisoParaEliminar == true ? (
                          <>
                            {" "}
                            <div
                              className="btn btn-sm btn-danger"
                              onClick={() => {
                                if (pagoRealizado.serie == "M") {
                                  toast.info(
                                    "No esta permitido eliminar pagos de mantenimiento"
                                  );
                                  return;
                                } else {
                                  setCrudAction({
                                    action: "delete",
                                    data: pagoRealizado,
                                  });
                                }
                              }}
                            >
                              Eliminar
                            </div>
                          </>
                        ) : null}
                      </div>
                      <div className="card-body p d-flex justify-content-between">
                        <strong>
                          <CalendarTodayIcon
                            className="text-success"
                            fontSize="small"
                          />
                          &nbsp;
                          {CambiarFormatoFechaHelper(pagoRealizado.fechaPago)}
                        </strong>

                        <strong>
                          {"$"}
                          {parseFloat(pagoRealizado.monto)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </strong>
                      </div>
                    </div>
                  </div>
                ))}

                {finalDataPagosRestructura.map((pagorestructura, index) => (
                  <>
                    {pagorestructura.pagosContrato.map((pago, indexxx) => (
                      <div key={indexxx} className="col d-none">
                        <div className="card mb-2">
                          <div className="card-header d-flex justify-content-between">
                            <strong>
                              <ConfirmationNumberIcon
                                className=""
                                fontSize="small"
                              />{" "}
                              &nbsp; {pago.serie} - {pago.folio}
                            </strong>
                            <strong>{pago.noContrato}</strong>
                            <span className="badge bg-success">
                              Histórico restructura
                            </span>
                          </div>
                          <div className="card-body p d-flex justify-content-between">
                            <strong>
                              <CalendarTodayIcon
                                className="text-success"
                                fontSize="small"
                              />
                              &nbsp;
                              {pago.fechaPago}
                            </strong>

                            <strong>
                              ${" "}
                              {parseFloat(pago.monto)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            </strong>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ))}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <h5 className="m-0 mt-2">Datos Generales</h5>
            <hr></hr>

            <ul className="list-group list-group-flush">
              <li className="list-group-item text-end">
                {permisoParaAgregar == true ? (
                  <>
                    <button
                      className="btn btn-success w-100"
                      onClick={() => {
                        setCrudAction({ action: "addPayment", data: null });
                      }}
                    >
                      Agregar pago
                    </button>
                  </>
                ) : null}
                {permisoParaAgregar == true ? (
                  <>
                    <button
                      className="btn btn-outline-success w-100 mt-2"
                      onClick={() => {
                        window.open(
                          "/ingresarpagos",
                          "_self",
                          "toolbar=1, scrollbars=1, resizable=1, width=" +
                            1015 +
                            ", height=" +
                            800
                        );
                      }}
                    >
                      Agregar pago Mantenimiento
                    </button>
                  </>
                ) : null}
                {crudAction.action == "addPayment" ? (
                  <AgregarPagoComponent
                    finalData={props.finalData}
                    contrato={props.contrato}
                    crudAction={crudAction}
                    setCrudAction={setCrudAction}
                    contadorActualizar={props.contadorActualizar}
                    setContadorActualizar={props.setContadorActualizar}
                  />
                ) : null}
                {crudAction.action == "edit" ? (
                  <EditarPagoComponent
                    finalData={props.finalData}
                    contrato={props.contrato}
                    crudAction={crudAction}
                    setCrudAction={setCrudAction}
                    contadorActualizar={props.contadorActualizar}
                    setContadorActualizar={props.setContadorActualizar}
                  />
                ) : null}

                {crudAction.action == "delete" ? (
                  <EliminarPagoContratoComponent
                    finalData={props.finalData}
                    contrato={props.contrato}
                    crudAction={crudAction}
                    setCrudAction={setCrudAction}
                    contadorActualizar={props.contadorActualizar}
                    setContadorActualizar={props.setContadorActualizar}
                  />
                ) : null}

                {crudAction.action == "editMantenimiento" ? (
                  <EditarPagoMantenimientoComponent
                    finalData={props.finalData}
                    contrato={props.contrato}
                    crudAction={crudAction}
                    setCrudAction={setCrudAction}
                    contadorActualizar={props.contadorActualizar}
                    setContadorActualizar={props.setContadorActualizar}
                  />
                ) : null}
                {crudAction.action == "changeContrato" ? (
                  <EditarPagoCambiarDeContratoComponent
                    finalData={props.finalData}
                    contrato={props.contrato}
                    crudAction={crudAction}
                    setCrudAction={setCrudAction}
                    contadorActualizar={props.contadorActualizar}
                    setContadorActualizar={props.setContadorActualizar}
                  />
                ) : null}
              </li>
              <li className="list-group-item text-end"></li>

              <li className="list-group-item">
                <strong>Total contrato: $</strong>
                {typeof props.finalData.etapaDos === "undefined" ||
                props.finalData.etapaDos.length === 0
                  ? 0
                  : parseFloat(props.finalData.etapaDos[0].importe)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              </li>

              <li className="list-group-item">
                <strong>No. pagos realizados:</strong>{" "}
                {parseFloat(totalPagosRealizados)
                  .toFixed(0)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              </li>
              <li className="list-group-item">
                <strong>Dinero Recaudado: $</strong>
                {totalDineroReacaudado
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              </li>
              <li className="list-group-item">
                <strong>Mantenimiento Recaudado: $</strong>
                {totalPagosMantenimiento
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              </li>

              <li className="list-group-item"></li>

              <li className="list-group-item">
                <strong>Resta por pagar:</strong> $
                {typeof props.finalData.etapaDos === "undefined" ||
                props.finalData.etapaDos.length === 0
                  ? 0
                  : parseFloat(props.finalData.etapaDos[0].saldo)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
