import { API } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";
import { NuevaAPICobranza } from "../Constants/ApiConnection";

export const getContratosPrevisionTop = async (user, params) => {
  let url = NuevaAPICobranza + "prevision/contratosMesCurso/";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
      params: params,
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};

export const newContract = async (user, values) => {
  let url = API + "contracts/newContract";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};

export const addContratoCancelado = async (user, values) => {
  let url = API + "contracts/cancelarFolioPrevision";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};

export const newContractRestructura = async (user, values) => {
  let url = API + "contracts/newRestructura";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};

export const newContractRestructuraMultiple = async (user, values) => {
  let url = API + "contracts/newRestructuraMultiple";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};
export const updateEtapaUno = async (user, values, idcontrato) => {
  let url = API + "contracts/updateContrato/" + idcontrato;

  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const updateEtapaTres = async (user, values) => {
  let url = API + "contracts/newFin/";

  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getLastContracts = async (user) => {
  let url = API + "contracts/getTop";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const searchContracts = async (user, idcontrato) => {
  let url = API + "contracts/busqueda/" + idcontrato;
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const searchEspaciosPorContratoPersonasFinado = async (
  user,
  idcontrato
) => {
  let url = API + "spaces/espacios/buscadorPersonas/" + idcontrato;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const searchPagosContracts = async (user, idcontrato) => {
  let url = API + "contracts/busquedaPagos/" + idcontrato;
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    response.data.sort(function (a, b) {
      return a.serie.localeCompare(b.serie);
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const searchPagosContractsFechas = async (user, values) => {
  let url = API + "contracts/buscarPagosFechaCaptura";
  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getDetailContract = async (user, idcontrato) => {
  let url = API + "contracts/getContractDetail/" + idcontrato;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const postGenerarEstadoDeCuentaAPI = async (user, values) => {
  let url = NuevaAPICobranza + "prevision/generarEstadoDeCuenta/";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const calcularMantenimientoContrato = async (user, idcontrato) => {
  let url = API + "contracts/calculoMantto/" + idcontrato;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const updateTitulares = async (user, values, idcontrato) => {
  let url = API + "contracts/newTitulares/" + idcontrato;

  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getListadoContratosPorUbicacion = async (user, idespacio) => {
  // let url = API + "contracts/listadoContratosUbicacion/" + idespacio;
  let url = API + "spaces/espacios/getEspaciosFiltro/" + idespacio;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      const NUMERIC_REGEXP = /[-]{0,1}[\d]*[.]{0,1}[\d]+/g;

      console.table(response.data);

      for (const contrato of response.data) {
        if (
          contrato.pagosMantto == "Sin fecha de asignación, imposible calcular"
        ) {
          contrato.porcentage = 0;
        } else {
          const resultado = contrato.pagosMantto.match(NUMERIC_REGEXP);

          console.log(resultado);

          if (resultado[0] == 0) {
            contrato.porcentage = 0;
          } else {
            let resultadoDos =
              (parseFloat(resultado[0]) * 100) / parseFloat(resultado[1]);

            contrato.porcentage = Math.round(resultadoDos);
          }
        }
      }

      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const deleteDestroyContrato = async (user, values) => {
  let url =
    process.env.REACT_APP_API_URL_ENDPOINT_COBRANZA +
    "prevision/eliminarContrato/" +
    values;

  try {
    const response = await axios.delete(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};
