import React from "react";
import { AuthContext } from "../../auth/authContext";
import { getComisionesDeUnContratoAPI } from "../../API/ApiComisionesDosPuntoCero";
import NoHayComisionistasComponent from "../NoHayComisionistasComponent/NoHayComisionistasComponent";
import { LinearProgress } from "@mui/material";

const ComisionesDosPuntoCeroComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [contadorActualizar, setContadorActualizar] = React.useState(0);

  const [finalData, setFinalData] = React.useState({});

  const [crud, setCrud] = React.useState({ type: null, data: null });

  React.useEffect(() => {
    if (props?.contrato) {
      getComisionesDeUnContratoAPI(user, props?.contrato).then((resultado) => {
        if (resultado.status == 200) {
          setFinalData(resultado.data);
        } else {
          setFinalData([]);
        }
      });
    }
  }, []);

  return (
    <>
      {Object.keys(finalData).length > 0 ? (
        <>
          <div className="container-fluid mt-5">
            <div className="row">
              <div className="col-md-4">
                <div className="card mb-3">
                  <div className="card-body py-4">
                    <strong className="mb-3"> Porcentaje de comisión: </strong>
                    {finalData?.comisiones?.porcentajePagoComision}
                    <LinearProgress variant="determinate" value={50} />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card mb-3">
                  <div className="card-body py-4">
                    <strong className="mb-3"> Total de comisión: </strong>
                    {finalData?.comisiones?.totalComision}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card mb-3">
                  <div className="card-body py-4">
                    <strong className="mb-3"> Pagos de cliente: </strong>
                    {finalData?.comisiones?.clientePagosAlMomento}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body p-4">
                    <h5 className="">Supervisor</h5>
                    <p>
                      {
                        finalData?.comisiones?.supervisor?.persona?.empleado
                          ?.nombre
                      }{" "}
                      {
                        finalData?.comisiones?.supervisor?.persona?.empleado
                          ?.apaterno
                      }{" "}
                      {
                        finalData?.comisiones?.supervisor?.persona?.empleado
                          ?.amaterno
                      }
                    </p>

                    <p className=" mt-2">
                      Total de comisión:{" "}
                      {finalData?.comisiones?.supervisor?.totalComision}
                      <br />
                      Total Pagado:{" "}
                      {finalData?.comisiones?.supervisor?.comisionPagada}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body p-4">
                    <h5 className="">Vendedor</h5>
                    <p>
                      {
                        finalData?.comisiones?.vendedor?.persona?.empleado
                          ?.nombre
                      }{" "}
                      {
                        finalData?.comisiones?.vendedor?.persona?.empleado
                          ?.apaterno
                      }{" "}
                      {
                        finalData?.comisiones?.vendedor?.persona?.empleado
                          ?.amaterno
                      }
                    </p>
                    <p className=" mt-2">
                      Total de comisión:{" "}
                      {finalData?.comisiones?.vendedor?.totalComision}
                      <br />
                      Total Pagado:{" "}
                      {finalData?.comisiones?.vendedor?.comisionPagada}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body p-4">
                    <h5 className="">Supervisor</h5>
                    <p>
                      {
                        finalData?.comisiones?.supervisor?.persona?.empleado
                          ?.nombre
                      }{" "}
                      {
                        finalData?.comisiones?.supervisor?.persona?.empleado
                          ?.apaterno
                      }{" "}
                      {
                        finalData?.comisiones?.supervisor?.persona?.empleado
                          ?.amaterno
                      }
                    </p>

                    <p className=" mt-2">
                      Total de comisión:{" "}
                      {finalData.comisiones?.supervisor?.totalComision}
                      <br />
                      Total Pagado:{" "}
                      {finalData?.comisiones?.supervisor?.comisionPagada}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <NoHayComisionistasComponent
          setLoading={props.setLoading}
          contrato={props?.contrato}
          setContadorActualizar={setContadorActualizar}
          contadorActualizar={contadorActualizar}
        />
      )}
    </>
  );
};

export default ComisionesDosPuntoCeroComponent;
